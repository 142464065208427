/* * {
    -webkit-transform: translateZ(0)!important;
    -moz-transform: translateZ(0)!important;
    -ms-transform: translateZ(0)!important;
    -o-transform: translateZ(0)!important;
    transform: translateZ(0)!important;

    -webkit-transform: translate3d(0, 0, 0)!important;
    -moz-transform: translate3d(0, 0, 0)!important;
    -ms-transform: translate3d(0, 0, 0)!important;
    transform: translate3d(0, 0, 0)!important;

    -webkit-backface-visibility: hidden!important;
    -moz-backface-visibility: hidden!important;
    -ms-backface-visibility: hidden!important;
    backface-visibility: hidden!important;

    -webkit-perspective: 1000!important;
    -moz-perspective: 1000!important;
    -ms-perspective: 1000!important;
    perspective: 1000!important;
} */

html, 
body, 
#root, 
#vstack-window {
    height: 100%;
    min-width: 300px;
}

a {
    text-decoration: none;
}

.text-primary {
    color:rgba(13,110,253,.7)!important
}

.text-primary-link {
    color:rgba(13,110,253,1)!important
}

.text-primary-link.not-active {
    color: #0a58ca !important;
}

#vstack-window {
    overflow: auto;
}

#vstack-window.dark {
    background-color: #181818;
    color:#f1f1f1;
}

#vstack-window.light {
    background-color: #f9f9f9;
    color:#181818;
}

#vstack-window.dark .text-normal {
    color:#f1f1f1;
}

#vstack-window.light .text-normal {
    color:#181818;
}

#vstack-window.light .bg-light {
    background-color: #ffffff!important;
}

.positive-change {
    color:#28bd98;
}

.negative-change {
    color: #ef5350;
}

#vstack-window.dark .border-top,
#vstack-window.dark .border-bottom,
#vstack-window.dark .border-start,
#vstack-window.dark .border-end {
    border-color: rgb(33, 37, 41)!important;
}

#vstack-window.light .border-top,
#vstack-window.light .border-bottom,
#vstack-window.light .border-start,
#vstack-window.light .border-end {
    border-color: #e1e1e1!important;
}

#vstack-window.dark .custom-ad-column-lg {
    background-color: rgb(33, 37, 41, .3)!important;
}

#vstack-window.light .custom-ad-column-lg {
    background-color: rgb(225, 225, 225, .3)!important;
}

.light .text-warning {
    color: #FF9800!important;
}

.custom-ad-column-lg,
.latest-articles-column-lg {
    width: 350px;
    max-width: 350px;
}

.latest-articles-column-lg {
    height: 438px;
    max-height: 438px;
    overflow: auto;
}

@media (min-width:1101px) {
    .latest-articles-column-lg {
        display: block!important;
    }

    .latest-articles-column-md {
        display: none!important;
    }
}

.latest-articles-column-md,
.latest-assets-column {
    max-height: 470px;
    overflow: auto;
    overflow-x: hidden;
}

.live-price-container .live-price-title {
    line-height: 24px;
}

.live-price-container .live-price-title span {
    vertical-align: middle;
}

@media (max-width: 767px) {
    .live-price-column,
    .top-stats-layout {
        border: none!important;
        margin-top: 12px;
    }
}

.twenty-four-hour-price {
    font-size: 13px;
    font-weight: bold;
    height: 100%;
}

.twenty-four-hour-price .row {
    height: 25%;
}

.stats-block .statistic:last-child {
    border: none!important;
}

.wide-market-stats-container {
    font-size: 11px;
    margin-top: 28px;
}

@media (min-width: 1351px) { 
    .wide-market-stats-col {
        display: block!important;
    }

    .market-cap-price-col,
    .twenty-four-hour-price-col,
    .twenty-four-hour-price-col-divider {
        display: none!important;
    }
}

#main-chart {
    height: 400px;
}

#vstack-window.dark .loading-spinner {
    background: rgb(33, 37, 41, .3);
}

#vstack-window.light .loading-spinner {
    background: rgb(225, 225, 225, .3)
}

.loading-spinner {
    height: 100%;
    width: 100%;
    display: flex;
    z-index: 4;
    align-items: center;
    justify-content: center;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 767px) { 
    .loading-spinner {
        background: transparent!important;
    }
 }

.loading-spinner .loading-text {
    margin-left: 15px;
    font-size: 20px;
    margin-top: -35px;
}

.nb-spinner {
    width: 35px;
    height: 35px;
    margin-top: -35px;
    background: transparent;
    border-top: 3px solid rgb(13, 110, 253);
    border-right: 3px solid transparent;
    border-radius: 50%;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    
    100% {
        transform: rotate(1turn);
    }
}

/* template */
/* Extra small devices (portrait phones, less than 576px) */
/* No media query since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {  }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {  }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  }